<template>
    <b-container fluid class="main-container">
        <b-row>
            <b-col xl="5" class="orders-pending-container">
                <div class="orders-list-wrapper">
                    <h3> {{ $t('headings.orders_list') }} </h3>
                    <div class="orders-list-header">
                        <b-container>
                            <b-row>
                                <b-col xl="1" lg="1">
                                    <div class="list-header-item">
                                        <p> {{ $t('tableHeadings.Id') }} </p>
                                    </div>
                                </b-col>
                                <b-col xl="4" lg="4">
                                    <div class="list-header-item">
                                        <p> {{ $t('tableHeadings.address') }} </p>
                                    </div>
                                </b-col>
                                <b-col xl="3" lg="3">
                                    <div class="list-header-item">
                                        <p> {{ $t('tableHeadings.phone') }} </p>
                                    </div>
                                </b-col>
                                <b-col xl="2" lg="2">
                                    <div class="list-header-item">
                                        <p> {{ $t('tableHeadings.hour') }}  </p>
                                    </div>
                                </b-col>
                                <b-col xl="2" lg="2">
                                    <div class="list-header-item">
                                        <p> {{ $t('tableHeadings.details') }} </p>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                    <div class="orders-list">
                        <draggable
                            class="dragArea list-group"
                            :list="unasignedOrders"
                            group="orders"
                            @change="changeMasterMap"
                          >
                            <div class="single-order"
                                v-for="order in unasignedOrders"
                                :key="order.id">
                                <b-container>
                                    <b-row>
                                        <b-col xl="1" lg="1">
                                            <div class="order-item">
                                                <p> {{order.id}} </p>
                                            </div>
                                        </b-col>
                                        <b-col xl="4" lg="4">
                                            <div class="order-item">
                                                <p> {{order.shippingAddress.address}} </p>
                                            </div>
                                        </b-col>
                                        <b-col xl="3" lg="3">
                                            <div class="order-item">
                                                <p> {{order.shippingAddress.phone}} </p>
                                            </div>
                                        </b-col>
                                        <b-col xl="2" lg="2">
                                            <div class="order-item">
                                                <p> {{order.created_at}}  </p>
                                            </div>
                                        </b-col>
                                        <b-col xl="2" lg="2">
                                            <div class="order-item" >
                                                <div class="general-icon-button trigger-detail-modal" @click="showDetailsModal(order)">
                                                    <i class="fas fa-th-list"></i>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </div>
                          </draggable>
                    </div>
                </div>
                <div class="orders-map-wrapper" :class="{active__map: mapVisible}">
                    <div class="map-slideout-trigger general-action-button" @click="mapVisible = !mapVisible">
                        <i class="fas fa-map-marked-alt"></i>
                    </div>
                    <div class="orders-map" id="master-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21703.752801117527!2d27.6014634!3d47.15845959999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sro!4v1579264479424!5m2!1sen!2sro" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                    </div>
                </div>
            </b-col>
            <b-col xl="7" class="cluster-boxes-container">
                <b-row class="highligted-section" :class="{set__overlay__fade: deliveriesVisible}">
                    <b-col xl="12">
                        <div class="cluster-boxes-wrapper">
                            <div class="cluster-box" :class="'car-container-' + key" v-for="(car, key) in deliveryCars" :key="'car-' + key">
                              <div :id="'overlay-car-section-' + key" class="cluster-box-overlay" style="display: none;">
                                  <div class="success-checkmark" :id="'success-' + key">
                                      <div class="check-icon">
                                        <span class="icon-line line-tip"></span>
                                        <span class="icon-line line-long"></span>
                                        <div class="icon-circle"></div>
                                        <div class="icon-fix"></div>
                                      </div>
                                    </div>
                                  <div class="cluster-box-loading" :id="'loading-' + key" style="display: none;">
                                     <div class="shape shape-1"></div>
                                     <div class="shape shape-2"></div>
                                     <div class="shape shape-3"></div>
                                     <div class="shape shape-4"></div>
                                 </div>

                              </div>
                                <div class="cluster-box-data">
                                    <div class="cluster-box-cta">
                                        <div class="cluster-asign-button cluster-box-button" @click="showPopUpAssignDeliveryGuy(key)">
                                            <i class="fas fa-truck"></i>
                                        </div>
                                        <div class="cluster-send-button cluster-box-button" @click="sendCar(key)">
                                            <i class="fas fa-check-square"></i>
                                        </div>
                                        <div class="cluster-box-button cluster-delete-button" @click="removeCar(key)">
                                            <i class="fas fa-trash-alt"></i>
                                        </div>
                                    </div>
                                    <h1 class="cluster-box-heading">  {{car.delivery_guy_name}} </h1>
                                    <div class="cluster-list">
                                        <draggable
                                                class="dragArea draggble-section drop-in-list"
                                                :list="car.orders"
                                                group="orders"
                                                @change="changeDeliveryCarMap('car-map-' + key, 11, key)"
                                        >
                                        <div class="cluster-list-item" v-for="order in car.orders" :key="order.id">
                                            <b-container>
                                                <b-row>
                                                    <b-col xl="1" lg="1">
                                                        <div class="order-item">
                                                            <p> {{order.id}} </p>
                                                        </div>
                                                    </b-col>
                                                    <b-col xl="7" lg="7">
                                                        <div class="order-item">
                                                            <p> {{order.shippingAddress.address}} </p>
                                                        </div>
                                                    </b-col>
                                                    <b-col xl="3" lg="3">
                                                        <div class="order-item">
                                                            <p> {{order.created_at}}  </p>
                                                        </div>
                                                    </b-col>
                                                    <b-col xl="1" lg="1">
                                                        <div class="order-item">
                                                            <div class="draggble-button-icon" @click="showDetailsModal(order)">
                                                                <i class="fas fa-th-list"></i>
                                                            </div>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                        </div>
                                    </draggable>
                                    </div>
                                </div>
                                <div class="cluster-box-map" :id="'car-map-' + key">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21703.752801117527!2d27.6014634!3d47.15845959999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sro!4v1579264479424!5m2!1sen!2sro" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <div class="sent-deliveries-slideout" :class="{active__deliveries: deliveriesVisible}">
                    <div class="trigger-view-sent-deliveries general-action-button" @click="deliveryCarsInProgress.length >= 1 ? (deliveriesVisible = !deliveriesVisible) : (deliveriesVisible = false)">
                        <i class="fas fa-truck"></i>
                    </div>
                    <div class="in-progress-deliveries">
                        <div class="cluster-box" v-for="(car, key) in deliveryCarsInProgress"  :key="'in-progress-car-' + key">
                            <div class="cluster-box-data">
                                <h1 class="cluster-box-heading"> {{car.delivery_guy_name}} </h1>
                                <div class="cluster-list">
                                    <div class="cluster-list-item" v-for="(order, key) in car.orders" :key="order.id">
                                        <b-container>
                                            <b-row>
                                                <b-col xl="1" lg="1">
                                                    <div class="order-item">
                                                        <p>
                                                          <span><i class="fas fa-circle" :style="'color: ' + statusColors[order.status.internal_code] + ';'"></i></span>
                                                          {{letters[key]}}{{order.id}}
                                                        </p>
                                                    </div>
                                                </b-col>
                                                <b-col xl="8" lg="8">
                                                    <div class="order-item">
                                                        <p> {{order.shippingAddress.address}} </p>
                                                    </div>
                                                </b-col>
                                                <b-col xl="3" lg="3">
                                                    <div class="order-item">
                                                        <p> {{order.created_at}}  </p>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </div>
                                </div>
                                <ul class="in-progress-info">
                                    <li class="in-progress-info-item">
                                        <p>
                                            <span> {{ $t('placeholder.delivered_at') }} </span>
                                            <span> {{car.orders[0].delivered_start_at}} </span>
                                        </p>
                                    </li>
                                    <li class="in-progress-info-item">
                                        <p>
                                            <span> {{ $t('placeholder.route_estimate') }} </span>
                                            <span :id="'estimated'+key"> {{car.orders[0].timeEstimated || '-' }} min </span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div class="cluster-box-map" :id="'in-progress-car-map-' + key">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21703.752801117527!2d27.6014634!3d47.15845959999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sro!4v1579264479424!5m2!1sen!2sro" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="add-new-item" @click="addNewCar()">
                  <i class="fas fa-plus-circle"></i>
                </div>
            </b-col>
        </b-row>
        <b-modal id="detail__modal" v-if="orderModal" :title="'Numar Comanda: ' + orderModal.id" hide-footer>
          <ul class="detail-modal-info">
              <li>
                  <p> {{ $t('placeholder.phone') }} {{orderModal.shippingAddress.phone}} </p>
              </li>
              <li>
                  <p> {{ $t('placeholder.address') }}{{orderModal.shippingAddress.address}}</p>
              </li>
              <li>
                  <p> {{ $t('placeholder.products') }} {{orderModal.products.length}}</p>
                  <ul class="detail-modal-products">
                      <li class="modal-single-product" v-for="(product, key) in orderModal.products" :key="'modal-prod'+key">
                          <p> ({{product.qty}}x) {{product.name}} </p>
                      </li>
                  </ul>
              </li>
              <li class="detail-modal-total">
                <span> {{ $t('placeholder.total') }} </span>
                <span> {{orderModal.total}} RON </span>
              </li>
          </ul>
        </b-modal>
    </b-container>
</template>

<script>
import draggable from 'vuedraggable'
import OrdersService from '../api-services/orders.service.js'

export default {
  name: 'custom-clone',
  display: 'Custom Clone',
  order: 3,
  components: {
    draggable
  },
  data () {
    return {
      letters: ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      statusColors: {
        1: 'blue',
        2: 'blue',
        3: 'yellow',
        4: 'green',
        5: 'red'
      },
      iasiCoordonates: new window.google.maps.LatLng(47.1585, 27.6014),
      orderModal: null,
      mapVisible: false,
      deliveriesVisible: false,
      masterMap: null,
      masterMapMarkers: [],
      unasignedOrders: null,
      unasignedOrdersCopy: null,
      deliveryGuys: [],
      deliveryCars: [
        { orders: [], delivery_guy_name: null, markers: [] },
        { orders: [], delivery_guy_name: null, markers: [] },
        { orders: [], delivery_guy_name: null, markers: [] }
      ],
      deliveryCarsInProgress: [],
      interval: null,
      time: null
    }
  },
  watch: {},
  methods: {
    showDetailsModal (order) {
      this.orderModal = order
      this.$bvModal.show('detail__modal')
    },
    changeMasterMap () {
      this.initMap(this.masterMap, this.unasignedOrdersCopy, 'master-map')
    },
    changeDeliveryCarMap (mapId, zoom, key, init = false) {
      this.initMap(this.deliveryCars[key].map, this.deliveryCars[key].orders, mapId, zoom, key, init)
    },
    getUndeliveredOrders (pusher = false) {
      OrdersService.todayUndelivered().then((response) => {
        if (response.data.status === 200) {
          if (!pusher) {
            this.unasignedOrders = response.data.data
            this.unasignedOrdersCopy = window._.clone(response.data.data)
          } else {
            for (let i in response.data.data) {
              if (!this.unasignedOrdersCopy.find(o => o.id === response.data.data[i].id)) {
                this.unasignedOrders.push(response.data.data[i])
                this.unasignedOrdersCopy.push(window._.clone(response.data.data[i]))
              }
            }
          }
          this.changeMasterMap()
        }
      })
    },
    getDeliveryGuys () {
      OrdersService.deliveryGuys().then((response) => {
        if (response.data.status === 200) {
          for (let i in response.data.data.deliveryGuys) {
            this.deliveryGuys[response.data.data.deliveryGuys[i].id] = response.data.data.deliveryGuys[i].name
          }
        }
      })
    },
    getOrdersInDelivery () {
      OrdersService.ordersInDelivery().then((response) => {
        if (response.data.status === 200) {
          this.deliveryCarsInProgress = response.data.data
          let vm = this
          for (let i in this.deliveryCarsInProgress) {
            this.deliveryCarsInProgress[i].markers = []
            setTimeout(function () {
              vm.mapWithDirections(i)
            }, 1500)
          }
        }
      })
    },
    removeCar (key) {
      this.$swal.fire({
        title: this.$t('alert.negative.certain_txt'),
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('alert.positive.confirm'),
        cancelButtonText: this.$t('alert.negative.cancel')
      }).then((result) => {
        if (result.value === true) {
          if (this.deliveryCars[key].orders) {
            let takeBackOrders = this.unasignedOrders.concat(this.deliveryCars[key].orders)
            this.unasignedOrders = takeBackOrders.sort((a, b) => parseInt(a.id) - parseInt(b.id))
          }
          for (let i in this.deliveryCars[key].markers) {
            this.deliveryCars[key].markers[i].setMap(null)
          }
          this.deliveryCars.splice(key, 1)
          for (let i in this.deliveryCars) {
            this.changeDeliveryCarMap('car-map-' + i, 11, i, true)
          }
          this.changeMasterMap()
        }
      })
    },
    sendCar (key) {
      if (!this.deliveryCars[key].delivery_guy_id) {
        this.showPopUpAssignDeliveryGuy(key, true)
      } else {
        let trip = this.deliveryCarsInProgress.find(d => parseInt(d.delivery_guy_id) === parseInt(this.deliveryCars[key].delivery_guy_id))
        if (trip) {
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.$t('alert.negative.delivery_man_asigned')
          })
          return false
        }
        this.$swal.fire({
          title: this.$t('alert.positive.send_car') + '(' + this.deliveryCars[key].delivery_guy_name + ')',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('alert.positive.confirm'),
          cancelButtonText: this.$t('alert.negative.cancel')
        }).then((result) => {
          if (result.value === true) {
            window.$('#overlay-car-section-' + key).fadeIn()
            let formData = new FormData()
            formData.append('delivery_guy_id', this.deliveryCars[key].delivery_guy_id)
            let orders = []
            for (let i in this.deliveryCars[key].orders) {
              orders.push(this.deliveryCars[key].orders[i].id)
            }
            formData.append('orders', orders)
            let vm = this
            OrdersService.assignDeliveryGuy(formData).then((response) => {
              if (response.status === 200) {
                window.$('#overlay-car-section-' + key + ' #loading-' + key).fadeOut()
                window.$('#overlay-car-section-' + key + ' #success-' + key).fadeIn()

                for (let i in orders) {
                  let existOrder = vm.unasignedOrdersCopy.find(o => parseInt(o.id) === parseInt(orders[i]))
                  let lastIndex = vm.unasignedOrdersCopy.indexOf(existOrder)
                  vm.unasignedOrdersCopy.splice(lastIndex, 1)
                }
                setTimeout(function () {
                  window.$('.car-container-' + key).addClass('animated__cluster__box')
                  setTimeout(function () {
                    window.$('#overlay-car-section-' + key).hide()
                    window.$('.car-container-' + key).removeClass('animated__cluster__box')
                    window.$('#overlay-car-section-' + key + ' #success-' + key).hide()
                    window.$('#overlay-car-section-' + key + ' #loading-' + key).show()
                    let time = new Date()
                    let deliveredStartAt = time.getHours() + ':' + time.getMinutes()
                    vm.deliveryCars[key].orders.forEach(function (o) {
                      o.delivered_start_at = deliveredStartAt
                      o.status = { internal_code: 3, name: 'In livrare' }
                    })

                    vm.deliveryCarsInProgress.push(window._.clone(vm.deliveryCars[key]))
                    setTimeout(function () {
                      vm.mapWithDirections(vm.deliveryCarsInProgress.length - 1, true)
                    }, 1000)
                    vm.deliveryCars.splice(key, 1)
                    for (let i in vm.deliveryCars) {
                      vm.changeDeliveryCarMap('car-map-' + i, 11, i, true)
                    }
                  }, 1500)
                }, 2500)
                this.changeMasterMap()
              }
            })
          }
        })
      }
    },
    addNewCar () {
      this.deliveryCars.push({ orders: [], delivery_guy_name: null, markers: [] })
    },
    showPopUpAssignDeliveryGuy (key, popUpSendCar = false) {
      this.$swal.fire({
        title: 'Livrator',
        input: 'select',
        inputOptions: this.deliveryGuys,
        inputPlaceholder: this.$t('alert.message.choose_delivery_man'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('alert.positive.confirm'),
        cancelButtonText: this.$t('alert.negative.cancel')
      }).then((result) => {
        this.deliveryCars[key].delivery_guy_name = this.deliveryGuys[result.value]
        this.deliveryCars[key].delivery_guy_id = result.value
        if (popUpSendCar && result.value) {
          this.sendCar(key)
        }
      })
    },
    async initMap (map, orders, mapId, zoom = 12, carIndex, init = false) {
      var mapOptions = {
        zoom: zoom,
        center: this.iasiCoordonates
      }

      if (!map || init) {
        map = new window.google.maps.Map(document.getElementById(mapId), mapOptions)
        if (mapId === 'master-map') {
          this.masterMap = map
        } else {
          this.deliveryCars[carIndex].map = map
        }
      }

      if (mapId === 'master-map') {
        for (let i in this.masterMapMarkers) {
          this.masterMapMarkers[i].setMap(null)
        }
      } else {
        for (let i in this.deliveryCars[carIndex].markers) {
          this.deliveryCars[carIndex].markers[i].setMap(null)
        }
      }

      var marker
      for (let i in orders) {
        let masterMapPresence = this.unasignedOrders.find(o => o.id === orders[i].id)
        marker = new window.google.maps.Marker(
          {
            position: {
              lat: parseFloat(orders[i].shippingAddress.lat),
              lng: parseFloat(orders[i].shippingAddress.long)
            },
            map: map,
            label: '' + orders[i].id,
            title: orders[i].created_at,
            // if the order is assigned in the right side set pin color to green
            icon: this.pinSymbol(masterMapPresence ? '#ea4335' : '#93ca62')
          }
        )

        // info window display on the pin map
        var content =
          '<div id="content">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h5 id="firstHeading" class="firstHeading"> #' + orders[i].id + '-' + orders[i].shippingAddress.address + '</h5>' +
            '<div id="bodyContent">' +
            '<p><b>Produse: </b> ' + orders[i].productsSummary + ' </p> ' +
            '<p><b>Telefon: </b> ' + orders[i].shippingAddress.phone + ' </p> ' +
            '<p><b>Total: </b> ' + orders[i].total + ' RON </p> ' +
            '<p><b>Plata: </b> ' + orders[i].paymentType + ' </p> ' +
            '</div>' +
          '</div>'

        var infowindow = new window.google.maps.InfoWindow()
        window.google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
          return function () {
            infowindow.setContent(content)
            infowindow.open(map, marker)
          }
        })(marker, content, infowindow))

        // store the markers on arrays to manipulate them after
        if (marker) {
          if (mapId === 'master-map') {
            this.masterMapMarkers.push(marker)
          } else {
            this.deliveryCars[carIndex].markers.push(marker)
          }
        }
      }
    },
    mapWithDirections (carIndex, init = false) {
      var mapOptions = {
        zoom: 11,
        center: this.iasiCoordonates
      }

      if (!this.deliveryCarsInProgress[carIndex].map || init) {
        this.deliveryCarsInProgress[carIndex].map = new window.google.maps.Map(document.getElementById('in-progress-car-map-' + carIndex), mapOptions)
      }
      this.deliveryCarsInProgress[carIndex].directionsService = new window.google.maps.DirectionsService()
      this.deliveryCarsInProgress[carIndex].directionsRenderer = new window.google.maps.DirectionsRenderer()
      this.deliveryCarsInProgress[carIndex].directionsRenderer.setMap(this.deliveryCarsInProgress[carIndex].map)

      var waypoints = []
      let orders = this.deliveryCarsInProgress[carIndex].orders
      for (let i in orders) {
        waypoints.push({
          // location: new window.google.maps.LatLng(orders[i].shippingAddress.address.lat, orders[i].shippingAddress.address.long),
          location: orders[i].shippingAddress.address,
          stopover: true
        })
      }
      var request = {
        origin: 'Bulevardul Tudor Vladimirescu 32, Iași',
        destination: 'Bulevardul Tudor Vladimirescu 32, Iași',
        waypoints: waypoints,
        optimizeWaypoints: false,
        provideRouteAlternatives: false,
        travelMode: 'DRIVING',
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: 'pessimistic'
        },
        unitSystem: window.google.maps.UnitSystem.IMPERIAL
      }
      let vm = this
      this.deliveryCarsInProgress[carIndex].directionsService.route(request, function (result, status) {
        if (status === 'OK') {
          let totalDuration = 0
          for (let i in result.routes[0].legs) {
            totalDuration += parseInt(result.routes[0].legs[i].duration.value / 60)
          }
          vm.deliveryCarsInProgress[carIndex].timeEstimated = totalDuration
          window.$('#estimated' + carIndex).html(totalDuration + ' min')
          vm.deliveryCarsInProgress[carIndex].directionsRenderer.setDirections(result)
        }
      })
      this.setDeliveryGuyMarker(carIndex)
    },
    pinSymbol (color) {
      return {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: color,
        strokeWeight: 2,
        labelOrigin: new window.google.maps.Point(0, -28),
        scale: 1
      }
    },
    setDeliveryGuyMarker (carIndex) {
      let vm = this

      for (let i in this.deliveryCarsInProgress[carIndex].markers) {
        this.deliveryCarsInProgress[carIndex].markers[i].setMap(null)
      }
      let marker = new window.google.maps.Marker(
        {
          position: {
            lat: parseFloat(vm.deliveryCarsInProgress[carIndex].delivery_guy_lat),
            lng: parseFloat(vm.deliveryCarsInProgress[carIndex].delivery_guy_lng)
          },
          map: this.deliveryCarsInProgress[carIndex].map,
          // label: '' + orders[i].id,
          icon: 'https://storage.googleapis.com/support-kms-prod/SNP_2752129_en_v0',
          title: vm.deliveryCarsInProgress[carIndex].delivery_guy_name
          // if the order is assigned in the right side set pin color to green
          // icon: this.pinSymbol(masterMapPresence ? '#ea4335' : '#93ca62')
        }
      )

      if (marker) {
        this.deliveryCarsInProgress[carIndex].markers.push(marker)
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  created () {
    window.Echo.channel('operator-new-order').listen('NewOrder', (e) => {
      this.getUndeliveredOrders(true)
    })
    window.Echo.channel('operator-delivery-trip-end').listen('DeliveryTripEnd', (e) => {
      let trip = this.deliveryCarsInProgress.find(d => parseInt(d.delivery_guy_id) === parseInt(e.message))
      if (trip) {
        this.deliveryCarsInProgress.splice(this.deliveryCarsInProgress.lastIndexOf(trip), 1)
      }
      if (this.deliveryCarsInProgress < 1) this.deliveriesVisible = false
    })
    window.Echo.channel('operator-delivery-new-position').listen('DeliveryNewPosition', (e) => {
      console.log(e)
      let trip = this.deliveryCarsInProgress.find(d => parseInt(d.delivery_guy_id) === parseInt(e.data.id))
      if (trip) {
        let index = this.deliveryCarsInProgress.lastIndexOf(trip)
        this.deliveryCarsInProgress[index].delivery_guy_lat = e.data.lat
        this.deliveryCarsInProgress[index].delivery_guy_lng = e.data.lng
        this.setDeliveryGuyMarker(index)
      }
    })
    window.Echo.channel('operator-order-status-update').listen('OrderStatusUpdate', (e) => {
      for (let i in this.deliveryCarsInProgress) {
        for (let j in this.deliveryCarsInProgress[i].orders) {
          if (parseInt(this.deliveryCarsInProgress[i].orders[j].id) === parseInt(e.data.id)) {
            this.deliveryCarsInProgress[i].orders[j].status = e.data.status
          }
        }
      }
      for (let i in this.deliveryCars) {
        for (let j in this.deliveryCars[i].orders) {
          if (parseInt(this.deliveryCars[i].orders[j].id) === parseInt(e.data.id)) {
            this.deliveryCars[i].orders[j].status = e.data.status
            if (e.data.status && parseInt(e.data.status.internal_code) === 5) {
              this.deliveryCars[i].orders.splice(j, 1)
              this.changeDeliveryCarMap('car-map-' + i, 11, i)
            }
          }
        }
      }

      if (e.data.status && parseInt(e.data.status.internal_code) === 5) {
        let ord = this.unasignedOrders.find(o => parseInt(o.id) === parseInt(e.data.id))
        if (ord) {
          this.unasignedOrders.splice(this.unasignedOrders.lastIndexOf(ord), 1)
        }
        ord = this.unasignedOrdersCopy.find(o => parseInt(o.id) === parseInt(e.data.id))
        if (ord) {
          this.unasignedOrdersCopy.splice(this.unasignedOrdersCopy.lastIndexOf(ord), 1)
        }
        this.changeMasterMap()
      }
    })
    this.getUndeliveredOrders()
    this.interval = setInterval(() => {
      this.getUndeliveredOrders()
    }, 3000)
    this.getOrdersInDelivery()
    this.getDeliveryGuys()
    this.$store.dispatch('hideLoading')
  }
}
</script>

<style>
  .sortable-ghost {
    background-color: #d8d8d8 !important;
    opacity: 1 !important;
  }
</style>
